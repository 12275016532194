<template>
  <div>
    <iframe
      v-if="checkAcessoCredor"
      src="https://app.powerbi.com/view?r=eyJrIjoiMGI0ZjQzY2YtYjMyZi00NmIyLTljNjQtNWY3NWNkYzI5NjA4IiwidCI6ImJkMzdmNTNmLWZiM2YtNGY2NS1hZWYxLTRkYWViYTIwNDA3MSJ9"
      frameborder="0"
      style="width: 100%; height: 80vh"
    />
  </div>
</template>

<script>
export default {

  computed: {
    checkAcessoCredor() {
      const { credores } = localStorage
      const { userId } = localStorage
      if (credores) {
        if (parseInt(userId) === 279) { return true }
      } else {
        return true
      }
      return false
    },
  },

}
</script>
